import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import cs from 'vuetify/lib/locale/cs'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { cs },
    current: 'cs'
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        hotelsOrange: '#f57f3f',
        hotelsBlue: '#46adf1',
        hotelsYellow: '#ffc107'
      }
    }
  }
})
