export default {
  methods: {
    countQuantity (arr, groupingProperty = 'id') {
      const sorted = arr.sort((a, b) => a[groupingProperty] - b[groupingProperty])
      const items = []; let prev

      for (let i = 0; i < sorted.length; i++) {
        if (sorted[i][groupingProperty] !== prev) {
          items.push(sorted[i])
        } else {
          items[items.length - 1].quantity += +sorted[i].quantity
        }
        prev = sorted[i][groupingProperty]
      }
      return items
    }
  }
}
