export const detailViews = {
  namespaced: true,
  state: {
    host: {},
    user: {},
    demand: {},
    reservation: {},
    property: {
      owner: {},
      promo: [], // pro zobrazovani detailu karty u hostitele
      advertising: []
    },
    globalAdvertisingSettings: {}
  },
  getters: {
    host: state => state.host,
    user: state => state.user,
    demand: state => state.demand,
    reservation: state => state.reservation,
    property: state => state.property,
    globalAdvertisingSettings: state => state.globalAdvertisingSettings,
    loadingSettings: state => !Object.keys(state.globalAdvertisingSettings).length
  },
  mutations: {
    setHost (state, host) {
      state.host = host
    },
    setUser (state, user) {
      state.user = user
    },
    setDemand (state, demand) {
      state.demand = demand
    },
    setReservation (state, reservation) {
      state.reservation = reservation
    },
    setProperty (state, property) {
      state.property = Object.assign({}, property)
      // state.property.equipment = [...property.equipment]
    },
    /**
     * @param state
     * @param {Number} id
     * @param {Object} promo
     */
    updatePropertyPromo (state, { id, promo }) {
      const index = state.property.promo.findIndex(promo => promo.id === id)
      state.property.promo.splice(index, 1, promo)
    }
  },
  actions: {}
}
