import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VCalendar from 'v-calendar'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import axios from 'axios'
import validationRules from '@/mixins/global/validationRules'
import requestHelpers from '@/mixins/global/requestHelpers'
import countQuantity from '@/mixins/global/countQuantity'
import VueApexCharts from 'vue-apexcharts'
import debounce from './mixins/global/debounce'
import helpers from './mixins/global/helpers'
import moment from 'moment-timezone'
import { localizedDate } from './filters'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL
Vue.prototype.$http = axios
Vue.prototype.momentTZ = moment
Vue.config.productionTip = false
Vue.mixin(validationRules)
Vue.mixin(requestHelpers)
Vue.mixin(countQuantity)
Vue.mixin(debounce)
Vue.mixin(helpers)
Vue.filter('localizedDate', localizedDate)

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />// ...other defaults
})
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})
// hide errors in production :)
if (process.env.NODE_ENV === 'production') {
// eslint-disable-next-line handle-callback-err
  Vue.config.errorHandler = function (err, vm, info) {
  }
}

new Vue({
  router,
  store,
  vuetify,
  mounted () {
    const userData = localStorage.getItem('user')
    const token = localStorage.getItem('accessToken')

    if (userData && token) {
      this.$store.commit('userAuth/setToken', token)
      this.$store.commit('userAuth/setUser', JSON.parse(userData))
    }

    this.$http.interceptors.request.use(config => {
      if (!config.url.includes('upload')) {
        config.data = requestHelpers.methods.keysToSnakeCase(config.data)
      }
      return config
    },
    error => {
      throw error
    }
    )

    this.$http.interceptors.response.use(config => {
      this.runningRequest = false
      config.data = requestHelpers.methods.keysToCamelCase(config.data)
      return config
    },
    error => {
      this.runningRequest = false
      // sem spadaji i CORS errory
      if (error.response === undefined) {
        this.$store.commit('alerts/clearAlerts')
        this.$store.dispatch('alerts/setAlerts', [{
          message: 'Chyba sítě',
          type: 'error'
        }])
        return
      }

      if (error.response.status === 401 && error.config.url !== '/login') {
        this.$store.dispatch('alerts/setAlerts', [{
          message: 'Platnost přihlášení vypršela',
          type: 'error'
        }])
        this.$store.commit('userAuth/resetAuth')
        this.$router.push('/')
        return
      }
      window.scrollTo(0, 0)
      if (error.response.status >= 500) {
        this.$store.commit('alerts/clearAlerts')
        this.$store.dispatch('alerts/setAlerts', [{ message: 'Chyba. Zkuste to prosím později.', type: 'error' }])
      }
      if (error.response.status >= 400 && error.response.status < 500) {
        this.$store.dispatch('alerts/setAlerts', [{ message: 'Chyba v požadavku.', type: 'error' }])
      }
      throw error
    }
    )
  },
  render: h => h(App)
}).$mount('#app')
