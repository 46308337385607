import { Property } from '@/store/entities/Property'
import { Room } from '@/store/entities/Room'

export const propertyCard = {
  namespaced: true,
  state: () => ({
    formStep: 1,
    property: {},
    editedRoom: new Room(0)
  }),
  getters: {
    formStep: state => state.formStep,
    property: state => state.property,
    formattedProperty: state => {
      const result = {
        ...state.property,
        locality: {
          displayName: state.property.locality.displayName || '',
          country: state.property.locality.address.country || '',
          region: state.property.locality.address.county || '',
          city: state.property.locality.address.city || state.property.locality.address.town || state.property.locality.address.village || '',
          streetNumber: state.property.locality.address.house_number || '',
          street: state.property.locality.address.village || state.property.locality.address.road || '',
          latitude: state.property.locality.lat,
          longitude: state.property.locality.lon
        },
        rooms: []
      }
      state.property.getRooms().forEach(room => {
        const resultRoom = {
          capacity: room.getCapacity(),
          name: room.getName(),
          price: room.getPrice(),
          roomsQuantity: room.getRoomsQuantity(),
          equipment: {},
          beds: []
        }

        room.getBeds().forEach(bed => {
          const resultBed = {
            type: bed.getType(),
            quantity: bed.getQuantity()
          }
          resultRoom.beds.push(resultBed)
        })

        Object.entries(room.getEquipment()).forEach(([id, value]) => {
          if (value && !id.includes('@the_place')) {
            resultRoom.equipment[id] = value
          } else {
            // eslint-disable-next-line no-prototype-builtins
            if (room.getEquipment().hasOwnProperty(`${id}@the_place`)) {
              if (room.getEquipment()[`${id}@the_place`]) {
                resultRoom.equipment[id] = value
              }
            }
          }
        })
        result.rooms.push(resultRoom)
      })
      return result
    },
    editedRoom: state => ({
      name: state.editedRoom.getName(),
      capacity: state.editedRoom.getCapacity(),
      beds: state.editedRoom.getBeds(),
      roomsQuantity: state.editedRoom.getRoomsQuantity(),
      price: state.editedRoom.getPrice(),
      equipment: state.editedRoom.getEquipment()
    })
  },
  mutations: {
    // ------------------------------------------- nova verze
    goToStep (state, step) {
      state.formStep = step
    },
    resetProperty (state) {
      state.property = new Property()
    },
    setPropertyTypeId (state, id) {
      state.property.propertyTypeId = id
    },
    createRoom (state) {
      state.editedRoom = new Room(state.property.getNextRoomId())
      state.property.incrementNextRoomId()
      state.editedRoom.addBed()
    },
    deleteRoom (state, { tmpId }) {
      state.property.deleteRoom({ tmpId })
    },

    /**
     * Creates deep copy of Room with selected tmpId and passes it to state.editedRoom, doesn't keep parent tmpId
     * @param state
     * @param {Number} tmpId
     * @return void
     */
    cloneRoom (state, { tmpId }) {
      state.editedRoom = new Room(state.property.getNextRoomId())
      state.property.incrementNextRoomId()
      state.editedRoom.cloneFrom(state.property.findRoom(tmpId))
      state.editedRoom.deleteParentTmpId()
      state.property.saveRoom(state.editedRoom)
    },
    createBed (state) {
      state.editedRoom.addBed()
    },
    /**
     * @param state
     * @param {Number} value new bedType value
     * @param {Number} tmpId bed tmpId
     * @return void
     */
    updateBedType (state, { value, tmpId }) {
      state.editedRoom.updateBedType({ value, tmpId })
    },
    /**
     * @param state
     * @param {Number} value new quantity value
     * @param {Number} tmpId bed tmpId
     * @return void
     */
    updateBedQuantity (state, { value, tmpId }) {
      state.editedRoom.updateBedQuantity({ value, tmpId })
    },
    /**
     * @param state
     * @param {Number} tmpId bed tmpId
     * @return void
     */
    removeBed (state, { tmpId }) {
      state.editedRoom.removeBed(tmpId)
    },

    /**
     * @param state
     * @return void
     */
    saveEditedRoom (state) {
      state.editedRoom.reduceBeds()
      state.property.saveRoom(state.editedRoom)
      // tady se ta instance vytvari zbytecne, ale jinak to rozbiji gettery => rozbitej getter rozbije form :)
      state.editedRoom = new Room(state.property.getNextRoomId())
    },
    /**
     * Creates deep copy of Room with selected tmpId and passes it to state.editedRoom, keeps the parentTmpId
     * @param state
     * @param {Number} tmpId
     * @return void
     */
    editRoom (state, { tmpId }) {
      state.editedRoom = new Room(state.property.getNextRoomId())
      state.property.incrementNextRoomId()
      state.editedRoom.cloneFrom(state.property.findRoom(tmpId))
    },
    saveEquipment (state, equipment) {
      state.editedRoom.setEquipment(equipment)
    },
    reset (state) {
      state.property = new Property()
      state.editedRoom = new Room(0)
    }
  }
}
