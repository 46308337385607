export default {
  data () {
    return {
      validation: {
        required: v => {
          if (typeof v === 'string') {
            return !!v.trim() || 'Vyplňte prosím toto pole.'
          } else
          if (Array.isArray(v)) {
            return !!v.length || 'Vyplňte prosím toto pole.'
          } else {
            return (!!v || v === 0) || 'Vyplňte prosím toto pole.'
          }
        },
        checked: v => !!v || 'Prosím potvrďte, že data byla zkontrolována.',
        email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Neplatný formát.',
        phone: v => {
          v = v || ''
          return v.length ? /^[0-9+][0-9 ]*$/.test(v) || 'Neplatná hodnota.' : true
        },
        // eslint-disable-next-line eqeqeq
        equal: compareTo => v => v === compareTo || 'Hodnoty se musí shodovat',
        number: v => v.length ? /^[0-9]+$/.test(v) || 'Neplatná hodnota.' : true,
        // floatNumber: v => (/^[0-9.]+$/.test(v.trim()) && isFinite(+v)) || 'Neplatná hodnota.',
        // tos: v => !!v || 'Musíte souhlasit s obchodními podmínkami.',
        minLength: min => v => {
          // implicitne true pro null/undefined/...
          if (!v) {
            return true
          }
          return v.length >= min || `Musí obsahovat alespoň ${min} znaků.`
        },
        maxLength: max => v => {
          // implicitne true pro null/undefined/...
          if (!v) {
            return true
          }
          return v.length <= max || `Nesmí obsahovat více než ${max} znaků.`
        },
        /* maxSize: bytes => v => (v ? v.size : 0) <= bytes || 'Soubor je příliš velký.',
        moneyMin: min => v => v >= min || 'Zadejte částku', */
        greaterThan: num => v => v > num || `Musí být větší než ${num}.`,
        lessThan: num => v => v < num || `Musí být menší než ${num}.`,
        all: arr => arr.every(val => !!val) || 'Vyplňte prosím všechna pole',
        fileTypes: typesArr => file => {
          // prazdny input
          if (!file) {
            return true
          }
          const fileNameParts = file.name.split('.')
          // soubor nema priponu
          if (fileNameParts === 1) {
            return 'Neplatný formát'
          }

          typesArr = typesArr.map(type => type.toUpperCase())
          const extension = fileNameParts.pop().toUpperCase()
          return typesArr.includes(extension) || 'Neplatný formát'
        }
      }
    }
  }
}
