import Vue from 'vue'
import Vuex from 'vuex'
import { propertyCard } from '@/store/modules/propertyCard'
import { userAuth } from '@/store/modules/userAuth'
import { alerts } from '@/store/modules/alerts'
import { detailViews } from '@/store/modules/detailViews'
import { search } from '@/store/modules/search'
import { priceSettings } from '@/store/modules/priceSettings'
import { propertyCardPhotos } from '@/store/modules/propertyCardPhotos'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    propertyCard,
    userAuth,
    alerts,
    detailViews,
    search,
    priceSettings,
    propertyCardPhotos
  }
})
