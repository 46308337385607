export const propertyCardPhotos = {
  namespaced: true,
  state: {
    photos: [],
    tmpIndex: 1
  },
  getters: {
    mainPhoto: state => state.photos.find(photo => photo.main),
    otherPhotos: state => state.photos.filter(photo => !photo.main)
  },
  mutations: {
    addPhoto (state, photo) {
      const newImageObject = {
        main: false,
        name: photo.name,
        photo: photo,
        tmpIndex: state.tmpIndex++
      }

      const reader = new FileReader()
      reader.onload = event => {
        newImageObject.previewPhotoUrl = event.target.result

        state.photos.push(newImageObject)
      }
      reader.readAsDataURL(photo)
    },
    setAsMainPhoto (state, photo) {
      state.photos.forEach(photo => {
        photo.main = false
      })
      photo.main = true
    },
    deletePhoto (state, photoToDelete) {
      const index = state.photos
        .map(p => p.tmpIndex)
        .indexOf(photoToDelete.tmpIndex)

      state.photos.splice(index, 1)
    },
    reset (state) {
      state.photos = []
      state.tmpIndex = 1
    }
  }
}
