export default {
  methods: {
    getLocalizedDate (date) {
      if (!date) {
        return null
      }
      if (!(date instanceof Date)) {
        if (typeof date === 'number') { // timestamps
          date = new Date(date * 1000)
        } else if (date.timestamp) {
          date = new Date(date.timestamp * 1000)
        } else {
          date = new Date(date)
        }
      }
      return date.toLocaleDateString('cs-CZ')
    },
    extractDate (date) {
      const year = date.getFullYear()
      let day = date.getDate()
      let month = date.getMonth() + 1

      if (month < 10) {
        month = '0' + month
      }

      if (day < 10) {
        day = '0' + day
      }

      return `${year}-${month}-${day}`
    }
  }
}
