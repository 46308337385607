export const admin = [
  {
    path: '/admin',
    component: () => import('../../components/layout/admin/Layout'),
    redirect: '/admin/dashboard',
    meta: {
      title: 'Admin panel',
      auth: true,
      roles: [1, 2]
    },
    children: [
      {
        path: 'dashboard',
        name: 'admin.dashboard',
        component: () => import('../../views/admin/Dashboard'),
        meta: {
          title: 'Dashboard',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'users',
        name: 'admin.users',
        component: () => import('../../views/admin/Users'),
        meta: {
          title: 'Uživatelé',
          auth: true,
          roles: [1, 2]
        },
        children: [

          {
            path: ':id',
            name: 'admin.users.user',
            component: () => import('../../views/admin/UserDetail'),
            meta: {
              title: 'Detail uživatele',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'hosts',
        name: 'admin.hosts',
        component: () => import('../../views/admin/Hosts'),
        meta: {
          title: 'Hostitelé',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.hosts.host',
            component: () => import('../../views/admin/HostDetail'),
            meta: {
              title: 'Detail hostitele',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'properties',
        name: 'admin.properties',
        component: () => import('../../views/admin/Cards'),
        meta: {
          title: 'Karty objektů',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.properties.property',
            component: () => import('../../views/admin/CardDetail'),
            meta: {
              title: 'Karta objektu',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'demands',
        name: 'admin.demands',
        component: () => import('../../views/admin/Demands'),
        meta: {
          title: 'Poptávky',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.demands.demand',
            component: () => import('../../views/admin/DemandDetail'),
            meta: {
              title: 'Detail poptávky',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'reservations',
        name: 'admin.reservations',
        component: () => import('../../views/admin/Reservations'),
        meta: {
          title: 'Rezervace',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.reservations.reservation',
            component: () => import('../../views/admin/ReservationDetail'),
            meta: {
              title: 'Detail rezervace',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'settings',
        name: 'admin.settings',
        component: () => import('../../views/admin/Settings'),
        meta: {
          title: 'Nastavení',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'reports',
        name: 'admin.reports',
        component: () => import('../../views/admin/Reports'),
        meta: {
          title: 'Nahlášení',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'feedback',
        name: 'admin.feedback',
        component: () => import('../../views/admin/Feedback'),
        meta: {
          title: 'Zpětná vazba',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'promo',
        name: 'admin.promo',
        component: () => import('../../views/admin/Promo'),
        meta: {
          title: 'Nastavení topování',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'coupons',
        name: 'admin.coupons',
        component: () => import('../../views/admin/Coupons'),
        meta: {
          title: 'Kupóny',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'emailing',
        name: 'admin.emailing',
        component: () => import('../../views/admin/Emailing'),
        meta: {
          title: 'Emailing',
          auth: true,
          roles: [1, 2]
        }
      },
      {
        path: 'users-archive',
        name: 'admin.users-archive',
        component: () => import('../../views/admin/UsersArchive'),
        meta: {
          title: 'Archiv uživatelů',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.users-archive.user',
            component: () => import('../../views/admin/UserDetail'),
            meta: {
              title: 'Detail archivovaného uživatele',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'hosts-archive',
        name: 'admin.hosts-archive',
        component: () => import('../../views/admin/HostsArchive'),
        meta: {
          title: 'Archiv hostitelů',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.hosts-archive.host',
            component: () => import('../../views/admin/HostDetail'),
            meta: {
              title: 'Detail archivovaného hostitele',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'properties-archive',
        name: 'admin.properties-archive',
        component: () => import('../../views/admin/CardsArchive'),
        meta: {
          title: 'Archiv objektů',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.properties-archive.property',
            component: () => import('../../views/admin/CardDetail'),
            meta: {
              title: 'Archivovaná karta objektu',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'demands-archive',
        name: 'admin.demands-archive',
        component: () => import('../../views/admin/DemandArchive'),
        meta: {
          title: 'Archiv poptávek',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.demands-archive.demand',
            component: () => import('../../views/admin/DemandDetail'),
            meta: {
              title: 'Detail archivované poptávky',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      },
      {
        path: 'reservations-archive',
        name: 'admin.reservations-archive',
        component: () => import('../../views/admin/ReservationsArchive'),
        meta: {
          title: 'Archiv rezervací',
          auth: true,
          roles: [1, 2]
        },
        children: [
          {
            path: ':id',
            name: 'admin.reservations.reservation',
            component: () => import('../../views/admin/ReservationDetail'),
            meta: {
              title: 'Detail archivované rezervace',
              auth: true,
              roles: [1, 2]
            }
          }
        ]
      }
    ]
  }
  // TODO: 404 route
  /*,
  {
    path: '*',
    name: 'notFound',
    component: () => import()
  } */
]
