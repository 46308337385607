import { Bed } from '@/store/entities/Bed'
import Vue from 'vue'

export class Room {
  #beds;
  #capacity;
  #name;
  #price;
  #equipment;
  #roomsQuantity;
  #tmpId; // id pokoje (pro identifikaci  ramci property)
  #parentTmpId; // pri editovani se vytvari nova instance (moznost zrusit zmeny) - potreba trackovat puvodni objekt
  #nextBedId; // cislovani posteli v ramci pokoje
  constructor (tmpId) {
    this.#tmpId = tmpId
    this.#parentTmpId = 0
    this.#capacity = null
    this.#name = null
    this.#price = null
    this.#equipment = {}
    this.#roomsQuantity = null
    this.#nextBedId = 1
    this.#beds = []
  }

  /**
   * Creates deep copy
   * @param {Room} original
   * @return void
   */
  cloneFrom (original) {
    this.#capacity = original.getCapacity()
    this.#name = original.getName()
    this.#price = original.getPrice()
    this.#roomsQuantity = original.getRoomsQuantity()
    this.#parentTmpId = original.getTmpId()
    this.#equipment = { ...original.getEquipment() }

    for (const bed of original.getBeds()) {
      this.addBed()
      // tmpId of last Bed
      const bedTmpId = this.#beds[this.#beds.length - 1].getTmpId()

      this.updateBedQuantity({
        value: bed.getQuantity(),
        tmpId: bedTmpId
      })
      this.updateBedType({
        value: bed.getType(),
        tmpId: bedTmpId
      })
    }
  }

  // START beds -------------------------------------------------------------
  /**
   * Counts bed quantities
   * @return {Number}
   */
  getBedCount () {
    return this.#beds.reduce((prev, next) => prev + +next.getQuantity(), 0)
  }

  addBed () {
    this.#beds.push(new Bed(this.#nextBedId++))
  }

  /**
   * @param {Number} value new bedType value
   * @param tmpId bed tmpId
   * @return void
   */
  updateBedType ({ value, tmpId }) {
    this.findBed(tmpId).setType(value)
  }

  /**
   * @param {Number} value new quantity value
   * @param tmpId bed tmpId
   * @return void
   */
  updateBedQuantity ({ value, tmpId }) {
    this.findBed(tmpId).setQuantity(value)
  }

  /**
   * @param {Number} tmpId
   * @return void
   */
  removeBed (tmpId) {
    const index = this.#beds
      .map(bed => bed.getTmpId())
      .indexOf(tmpId)

    this.#beds.splice(index, 1)
  }

  /**
   * @param tmpId
   * @return {Bed}
   */
  findBed (tmpId) {
    return this.#beds.find((bed) => bed.getTmpId() === tmpId)
  }

  /**
   * Removes duplicate beds and replaces them with one that combines their quantities
   * @return void
   */
  reduceBeds () {
    // vyfiltrovani podle typu
    const filteredBeds = [];
    [1, 2, 3, 4].forEach(bedType => {
      filteredBeds[bedType] = this.#beds.filter(bed => bed.getType() === bedType)
    })

    for (let i = 1; i <= 4; i++) {
      if (filteredBeds[i].length > 1) {
        let sum = +filteredBeds[i][0].getQuantity()
        // pricteni quantity ostatnich beds k quantity prvni bed z vyfiltrovaneho pole
        for (let j = 1; j < filteredBeds[i].length; j++) {
          sum += +filteredBeds[i][j].getQuantity()
          this.removeBed(filteredBeds[i][j].getTmpId())
        }
        this.updateBedQuantity({ value: sum, tmpId: filteredBeds[i][0].getTmpId() })
      }
    }
  }
  // END beds ----------------------------------------------------------

  // START getters / setters --------------------------------------------
  getTmpId () {
    return this.#tmpId
  }

  setName (name) {
    this.#name = name
  }

  getName () {
    return this.#name
  }

  setPrice (price) {
    this.#price = price
  }

  getPrice () {
    return this.#price
  }

  setCapacity (capacity) {
    this.#capacity = capacity
  }

  getCapacity () {
    return this.#capacity
  }

  setRoomsQuantity (quantity) {
    this.#roomsQuantity = quantity
  }

  getRoomsQuantity () {
    return this.#roomsQuantity
  }

  /**
   * Deep clones equipment
   * @param {Object} equipment
   * @return void
   */
  setEquipment (equipment) {
    for (const equipmentTypeId in equipment) {
      Vue.set(this.#equipment, equipmentTypeId, equipment[equipmentTypeId])
      // this.#equipment[equipmentTypeId] = equipment[equipmentTypeId]
    }
  }

  getEquipment () {
    return { ...this.#equipment }
  }

  getBeds () {
    return this.#beds
  }

  getParentTmpId () {
    return this.#parentTmpId
  }

  deleteParentTmpId () {
    this.#parentTmpId = 0
  }
  // END getters / setters -------------------------------------------
}
