export const user = [
  {
    path: '/user',
    component: () => import('../../components/layout/user/Layout'),
    redirect: '/user/demands',
    children: [
      {
        path: 'create-demand',
        name: 'create-demand',
        component: () => import('../../views/user/CreateDemand'),
        meta: {
          title: 'Nová poptávka',
          auth: true,
          roles: [4]
        }
      },
      {
        path: 'chats',
        name: 'user.chats',
        component: () => import('../../views/user/UserChats'),
        meta: {
          title: 'Chaty',
          auth: true,
          roles: [4]
        }
      },
      {
        path: 'favourite-properties',
        name: 'user.favourite-properties',
        component: () => import('../../views/user/FavoriteProperties'),
        meta: {
          title: 'Oblíbené objekty',
          auth: true,
          roles: [4]
        }
      },
      {
        path: 'demands',
        name: 'user.demands',
        component: () => import('../../views/user/Demands'),
        meta: {
          title: 'Poptávky',
          auth: true,
          roles: [4]
        }
      },
      {
        path: 'reservations',
        name: 'user.reservations',
        component: () => import('../../views/user/Reservations'),
        meta: {
          title: 'Rezervace',
          auth: true,
          roles: [4]
        }
      },
      {
        path: 'profile',
        name: 'user.profile',
        component: () => import('../../views/user/Profile'),
        meta: {
          title: 'Profil',
          auth: true,
          roles: [4]
        }
      }
    ]
  }
]
