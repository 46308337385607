export function localizedDate (val) {
  if (!val) {
    return ''
  }
  if (!(val instanceof Date)) {
    if (typeof val === 'number') {
      val = new Date(val * 1000)
    } else if (val.timestamp) {
      val = new Date(val.timestamp * 1000)
    } else {
      val = new Date(val)
    }
  }
  return val.toLocaleDateString('cs-CZ')
}
