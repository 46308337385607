import axios from 'axios'

export const search = {
  namespaced: true,
  state: {
    fetchingResults: false,
    results: [],
    page: 1,
    searchParameters: {
      propertyTypeIds: [],
      priceFilterType: 0, // 0 - za noc; 1 - za pobyt
      price: {
        min: 0,
        max: 5000
      },
      locality: {}, // nominatim
      rating: [],
      equipment: [],
      beds: null,
      bedrooms: null,
      location: {},
      adults: null,
      children: null
    }
  },
  getters: {
    searchParameters: state => {
      // console.log('state', state)
      const formatted = {
        ...state.searchParameters,
        locality: {}
      }

      // console.log(state.searchParameters)
      formatted.locality.displayName = state.searchParameters.locality.displayName || ''
      if (state.searchParameters.locality.address) {
        formatted.locality.country = state.searchParameters.locality.address.country || ''
        formatted.locality.region = state.searchParameters.locality.address.county || ''
        formatted.locality.city = state.searchParameters.locality.address.city || state.searchParameters.locality.address.town || state.searchParameters.locality.address.village || ''
        formatted.locality.streetNumber = state.searchParameters.locality.address.house_number || ''
        formatted.locality.street = state.searchParameters.locality.address.village || state.searchParameters.locality.address.road || ''
        formatted.locality.latitude = state.searchParameters.locality.lat
        formatted.locality.longitude = state.searchParameters.locality.lon
      }
      return formatted
    },
    results: state => state.results,
    fetchingResults: state => state.fetchingResults
  },
  mutations: {
    setFetchingResults (state, fetching) {
      state.fetchingResults = fetching
    },
    chainResults (state, results) {
      state.results = [...state.results, ...results]
    },
    setResults (state, results) {
      state.results = results
    },
    setSearchParameters (state, params) {
      state.searchParameters = { ...state.searchParameters, ...params }
    }
  },
  actions: {
    search ({
      commit,
      state
    }) {
      if (!state.searchParameters.locality.address) {
        return
      }
      state.page = 1
      scrollTo(0, 0)
      commit('setFetchingResults', true)
      commit('setResults', [])

      const params = {
        page: 1,
        ...state.searchParameters
      }
      params.locality.country = state.searchParameters.locality.address.country || ''
      params.locality.region = state.searchParameters.locality.address.county || ''
      params.locality.city = state.searchParameters.locality.address.city || state.searchParameters.locality.address.town || state.searchParameters.locality.address.village || ''
      params.locality.streetNumber = state.searchParameters.locality.address.house_number || ''
      params.locality.street = state.searchParameters.locality.address.village || state.searchParameters.locality.address.road || ''
      params.locality.latitude = state.searchParameters.locality.lat
      params.locality.longitude = state.searchParameters.locality.lon

      // pocet posteli implicitne stejny jako pocet lidi (jinak to rozbije PHP :))
      if (!params.beds) {
        params.beds = params.adults + params.children
      }

      axios.post('/search', params)
        .then(res => {
          commit('setResults', res.data)
          commit('setFetchingResults', false)
        })
    }
  }
}
