<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  watch: {
    $route: {
      immediate: true,
      handler (to, from) {
        document.title = to.meta.title + ' | Rezervační systém' || 'Rezervační systém'
      }
    }
  },
  data: () => ({
    //
  })
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400&family=Poppins:wght@400;500;700&family=Readex+Pro:wght@400;700&family=Roboto:wght@400;700&display=swap');
.v-dialog, .v-card{
  border-radius: 0 !important;
}
.vc-container{
  border-radius: 0 !important;
}
.custom-heading{
  font-size: 26px;
  font-weight: bold;
}
.custom-heading-2{
  font-size: 24px;
}

</style>
