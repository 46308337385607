export class Bed {
  #tmpId;
  #type;
  #quantity
  constructor (tmpId) {
    this.#tmpId = tmpId
    this.#quantity = null
    this.#type = 0
  }

  getTmpId () {
    return this.#tmpId
  }
  /**
   * @return {Number}
   */

  getQuantity () {
    return this.#quantity
  }

  /**
   * @param {Number} quantity
   * @return void
   */
  setQuantity (quantity) {
    this.#quantity = quantity
  }

  /**
   * @param {Number} type
   * @return void
   */
  setType (type) {
    this.#type = type
  }

  /**
   * @return {Number}
   */
  getType () {
    return this.#type
  }
}
