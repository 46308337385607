import axios from 'axios'
import router from '@/router'

export const userAuth = {
  namespaced: true,
  state: () => ({
    user: {
      roleId: null
    },
    accessToken: null
  }),
  getters: {
    user: state => state.user,
    token: state => state.accessToken,
    isAdmin: state => state.user.roleId === 1 || state.user.roleId === 2,
    isHost: state => state.user.roleId === 3,
    isUser: state => state.user.roleId === 4,
    isLogged: state => !!state.user.id
  },
  mutations: {
    setToken: (state, newToken) => {
      localStorage.setItem('accessToken', newToken)
      axios.defaults.headers.common.Authorization = `Bearer ${newToken}`
      state.accessToken = newToken
    },
    resetAuth: state => {
      state.user = {
        roleId: null
      }
      delete axios.defaults.headers.common.Authorization
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      state.accessToken = null
    },
    setUser: (state, user) => {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user))
    }
  },
  actions: {
    logout ({ commit }) {
      axios.post('logout')
        .then(() => {
          commit('resetAuth')
          router.push({ name: 'homepage' })
        })
    }
  }
}
