import axios from 'axios'

export const alerts = {
  namespaced: true,
  state: () => ({
    defaultAlert: {
      message: '',
      type: ''
    },
    permanentAlerts: [],
    alerts: []
  }),
  getters: {
    alerts: state => state.alerts,
    permanentAlerts: state => state.permanentAlerts
  },
  mutations: {
    clearAlerts (state) {
      state.alerts = []
    },
    createAlerts (state, alertsArr) {
      state.alerts = alertsArr
    },
    setPermanentAlerts (state, alertsArr) {
      state.permanentAlerts = alertsArr
    },
    removePermanentAlerts (state) {
      state.permanentAlerts = []
    }
  },
  actions: {
    setAlerts ({ commit, state }, alerts) {
      commit('createAlerts', alerts)
      setTimeout(() => {
        commit('clearAlerts')
      }, 5000)
    },
    loadAlerts ({ commit }) {
      axios.get('/alerts')
        .then(res => {
          commit('setPermanentAlerts', res.data)
        })
    }
  }
}
