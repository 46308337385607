export const host = [
  {
    path: '/host',
    component: () => import('../../components/layout/host/Layout'),
    redirect: '/host/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'host.dashboard',
        component: () => import('../../views/host/Dashboard'),
        meta: {
          title: 'Dashboard',
          auth: true,
          roles: [3]
        }
      },
      {
        path: 'properties',
        name: 'host.properties',
        component: () => import('../../views/host/Cards'),
        meta: {
          title: 'Karty objektů',
          auth: true,
          roles: [3]
        },
        children: [
          {
            path: 'create',
            name: 'host.properties.create',
            component: () => import('../../views/host/CardCreate'),
            meta: {
              title: 'Nová karta',
              auth: true,
              roles: [3]
            }
          },
          {
            path: ':id',
            name: 'host.properties.property',
            component: () => import('../../views/host/CardDetail'),
            meta: {
              title: 'Karta objektu',
              auth: true,
              roles: [3]
            }
          }
        ]
      },
      {
        path: 'demands',
        name: 'host.demands',
        component: () => import('../../views/host/Demands'),
        meta: {
          title: 'Poptávky',
          auth: true,
          roles: [3]
        },
        children: [
          {
            path: ':id',
            name: 'host.demands.demand',
            component: () => import('../../views/admin/DemandDetail'),
            meta: {
              title: 'Detail poptávky',
              auth: true,
              roles: [3]
            }
          }
        ]
      },
      {
        path: 'reservations',
        name: 'host.reservations',
        component: () => import('../../views/host/Reservations'),
        meta: {
          title: 'Rezervace',
          auth: true,
          roles: [3]
        },
        children: [
          {
            path: ':id',
            name: 'host.reservations.reservation',
            component: () => import('../../views/admin/ReservationDetail'),
            meta: {
              title: 'Detail rezervace',
              auth: true,
              roles: [3]
            }
          }
        ]
      },
      {
        path: 'chats',
        name: 'host.chats',
        component: () => import('../../views/host/Chats'),
        meta: {
          title: 'Chaty',
          auth: true,
          roles: [3]
        }
      }
    ]
  }
]
