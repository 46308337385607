export default {
  methods: {
    keysToCamelCase (anyValue) {
      if (Array.isArray(anyValue)) {
        return anyValue.map(element => this.keysToCamelCase(element))
      } else if (anyValue === Object(anyValue)) {
        const newObj = {}
        Object.keys(anyValue).forEach(key => {
          const newKeyArr = key.split('_')
          for (let i = 1; i < newKeyArr.length; i++) {
            newKeyArr[i] = newKeyArr[i][0].toUpperCase() + newKeyArr[i].slice(1)
          }
          const newKey = newKeyArr.join('')
          newObj[newKey] = this.keysToCamelCase(anyValue[key])
        })
        return newObj
      } else {
        return anyValue
      }
    },
    keysToSnakeCase (anyValue) {
      if (Array.isArray(anyValue)) {
        return anyValue.map(element => this.keysToSnakeCase(element))
      } else if (anyValue === Object(anyValue)) {
        const newObj = {}
        Object.keys(anyValue).forEach(key => {
          const newKey = this.camelToSnakeCase(key)
          newObj[newKey] = this.keysToSnakeCase(anyValue[key])
        })
        return newObj
      } else {
        return anyValue
      }
    },
    camelToSnakeCase (string) {
      return string.split(/(?=[A-Z])/).join('_').toLowerCase()
    }
  }
}
