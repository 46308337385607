export class Property {
  #id;
  // Property Rooms have inner ids for searching
  #nextRoomId;
  name;
  locality;
  propertyTypeId;
  hostLanguages;
  descriptionPreview;
  description;
  ownerId;
  stars;
  contactPersonName;
  contactPersonPhone;
  contactPersonEmail;
  checkInFrom;
  checkOutFrom;
  checkInTo;
  checkOutTo;
  rooms;
  constructor () {
    this.#id = null
    this.name = null
    this.locality = null
    this.propertyTypeId = null
    this.hostLanguages = []
    this.descriptionPreview = null
    this.description = null
    this.ownerId = null
    this.stars = 1
    this.contactPersonName = null
    this.contactPersonPhone = null
    this.contactPersonEmail = null
    this.checkInFrom = null
    this.checkOutFrom = null
    this.checkInTo = null
    this.checkOutTo = null
    this.rooms = []

    this.#nextRoomId = 1
  }

  /**
   * Sets data for updating property
   * @param {Object} data
   * @param {String} data.name
   * @param {Object} data.locality
   * @param {Number} data.propertyTypeId
   * @param {String[]} data.hostLanguages
   * @param {String} data.descriptionPreview
   * @param {String} data.description
   * @param {Number} data.ownerId
   * @param {Number} data.stars
   * @param {String} data.contactPersonName
   * @param {String} data.contactPersonPhone
   * @param {String} data.contactPersonEmail
   * @param {String} data.checkInFrom
   * @param {String} data.checkOutFrom
   * @param {String} data.checkInTo
   * @param {String} data.checkOutTo
   * @param {Object[]} data.rooms
   * @return void
   */
  loadData (data) {
    // TODO vypsat
  }

  /**
   * @return {Room[]}
   */
  getRooms () {
    return this.rooms
  }

  /**
   * @return {Number}
   */
  getNextRoomId () {
    return this.#nextRoomId
  }

  /**
   * @return void
   */
  incrementNextRoomId () {
    this.#nextRoomId++
  }

  /**
   * @param tmpId
   * @return {Room}
   */
  findRoom (tmpId) {
    const res = this.rooms.find((room) => {
      return room.getTmpId() === tmpId
    })
    return res
  }

  /**
   * @param tmpId
   * @return {Number}
   */
  indexOfRoom (tmpId) {
    return this.rooms
      .map(room => room.getTmpId())
      .indexOf(tmpId)
  }

  /**
   * Adds new Room or overwrites existing one
   * @param {Room} room !instance that nothing except state.editedRoom is pointing to!
   * @return void
   */
  saveRoom (room) {
    const index = this.indexOfRoom(room.getParentTmpId())

    if (index !== -1) {
      this.rooms.splice(index, 1, room)
    } else {
      this.rooms.push(room)
    }
  }

  /**
   * @param {Number} tmpId
   * @return void
   */
  deleteRoom ({ tmpId }) {
    const index = this.indexOfRoom(tmpId)
    this.rooms.splice(index, 1)
  }

  /**
   * @return {Number|null}
   */
  getId () {
    return this.#id
  }

  /**
   * @param {Number} id
   */
  setId (id) {
    this.#id = id
  }
}
