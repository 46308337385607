export const auth = [
  {
    path: '/verification-success',
    name: 'verificationSuccess',
    component: () => import('../../views/auth/VerificationSuccess'),
    meta: {
      title: 'Ověření'
    }
  },
  {
    path: '/invalid-signature',
    name: 'invalidSignature',
    component: () => import('../../views/auth/InvalidSignature'),
    meta: {
      title: 'Chyba ověření'
    }
  }
]
