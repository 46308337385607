export const priceSettings = {
  namespaced: true,
  state: {
    editedRoom: {}
  },
  getters: {
    editedRoom: state => state.editedRoom
  },
  mutations: {
    setEditedRoom (state, room) {
      state.editedRoomId = room
    }
  }
}
