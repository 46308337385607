export const anonymous = [
  {
    path: '/',
    redirect: '/homepage',
    component: () => import('../../components/layout/anonymous/Layout'),
    children: [
      {
        path: 'homepage',
        name: 'homepage',
        component: () => import('../../views/anonymous/Homepage'),
        meta: {
          title: 'Domovská stránka',
          auth: null
        }
      },
      {
        path: 'test',
        name: 'test',
        component: () => import('../../views/Testy')
      },
      {
        path: 'search-result',
        name: 'search-result',
        component: () => import('../../views/anonymous/SearchResults'),
        meta: {
          title: 'Vyhledávání',
          auth: null
        }
      },
      {
        path: 'property-detail/:id',
        name: 'property-detail',
        component: () => import('../../views/anonymous/PropertyDetail'),
        meta: {
          title: 'Karta objektu',
          auth: null
        }
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('../../components/auth/Register'),
        meta: {
          title: 'Registrace',
          auth: false
        }
      },
      {
        path: 'about',
        name: 'about',
        component: () => import('../../views/anonymous/About'),
        meta: {
          title: 'O webu'
        }
      },
      {
        path: 'contacts',
        name: 'contacts',
        component: () => import('../../views/anonymous/Contacts'),
        meta: {
          title: 'Kontakty'
        }
      }/* ,
      {
        path: '/login',
        name: 'login',
        component: () => import('../../views/anonymous/Login')
      } */
    ]
  },
  {
    path: '*',
    name: 'notFound',
    component: () => import('../../views/404')
  }
]
